import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { Button, Menu, MenuItem } from '@haravan/react-components'
import { DataSave, Tab, TabItem } from '../../../components'
import { PermissionRepository } from '../../../repositories'
import { dataSaved, selectChangedData } from '../../../components/data_save/data_save_slice'
import { RequestAccess, UpdateSubscription, UpdateKeyAccount } from './actions/index'
import { openEditorPopup } from '../../../components/popup/popup_slice'
import { selectUser } from '../../auth/services/auth_slice'
import { Dropdown } from "react-bootstrap";

import './index.css'

export const ShopDetail = () => {
  const { shopId } = useParams()
  const { pathname } = useLocation()

  const dispatch = useDispatch()

  const changedData = useSelector(selectChangedData)
  const user = useSelector(selectUser)

  const [activeTab, setActiveTab] = useState('websiteInfo')
  const [canUpdateShopPlan, setCanUpdateShopPlan] = useState(false)
  const [canRequestAccess, setCanRequestAccess] = useState(false)
  useEffect(() => {
    let paths = pathname.split('/')
    let endPath = paths[paths.length - 1]
    let nearEndPath = paths[paths.length - 2]
    setActiveTab(Number.isInteger(endPath) ? nearEndPath : endPath)
  }, [pathname])

  const openUserAccessPopup = () => {
    dispatch(
      openEditorPopup({
        title: 'Cấp quyền người dùng',
        acceptText: '',
        cancelText: '',
        content: <RequestAccess />,
        closeOnClickOutside: false,
        class_name: 'popup_user_access'
      })
    )
  }

  const openUpdateSubscriptionPopup = () => {
    dispatch(
      openEditorPopup({
        title: 'Cập nhật Shop Plan',
        acceptText: '',
        cancelText: '',
        content: <UpdateSubscription />,
        closeOnClickOutside: false,
        class_name: 'popup_user_access'
      })
    )
  }

  const openUpdateKeyAccountPopup = () => {
      dispatch(
          openEditorPopup({
              title: 'Cập nhật KeyAccount',
              acceptText: '',
              cancelText: '',
              content: <UpdateKeyAccount/>,
              closeOnClickOutside: false,
              class_name: 'popup_key_account'
          })
      )
  }

  useEffect(() => {
    user &&
    PermissionRepository.GetFunctions(user.profile?.sub).then(rs => {
        setCanRequestAccess(rs?.data?.findIndex(r => r.code === 'inside.shop.request_access') > -1)
        setCanUpdateShopPlan(rs?.data?.findIndex(r => r.code === 'inside.shop.update_shopplan') > -1)
      })
  }, [user])

  let content = <Menu>
    <MenuItem disabled><b>Header 1</b></MenuItem>
  </Menu>

  return (
    <div className='shop-detail-wrapper'>
      <div className='justify-right'>
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            Thao tác
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {canRequestAccess && <Dropdown.Item onClick={openUserAccessPopup}>Cấp quyền truy cập</Dropdown.Item>}
            {canUpdateShopPlan && <Dropdown.Item onClick={openUpdateSubscriptionPopup}>Cập nhật Shop Plan</Dropdown.Item>}
            {canUpdateShopPlan && <Dropdown.Item onClick={openUpdateKeyAccountPopup}>Cập nhật KeyAccount</Dropdown.Item>}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Tab>
        <TabItem path='.' name='Thông tin cửa hàng' />
        <TabItem path='transactions' name='Lịch sử thanh toán' />
        <TabItem path='tickets' name='Tickets' />
        <TabItem path='appinstalls' name='Lịch sử cài ứng dụng' />
        <TabItem path='themeinstalls' name='Lịch sử cài theme' />
      </Tab>
    </div>
  )
}
